import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import Main from "../components/Main";

import { PageHeader } from "../components/Typo";
import QuoteBox from "../components/QuoteBox";
import MediaBottom from "../components/MediaBottom";

import band from "../images/band.jpg";
import paars from "../images/paars.jpg";

const Bio = styled.div`
  max-width: 960px;
  margin: auto;

  p {
    padding: 0px 16px;
  }

  .feature-image {
    margin: 32px 16px;

    width: calc(100% - 32px);

    ${media.lessThan("medium")`
      margin: 32px auto;
    `};
  }

  ${media.greaterThan("medium")`
    columns: 2;
    column-gap: 76px;  
  `}
`;

export default () => {
  return (
    <Main title="Wie is Emma Lou?" path="/wie">
      <PageHeader title="Wie is Emma Lou?" icon="id" />
      <Bio>
        <p style={{ marginTop: 0 }}>
          Emma Lou brengt nieuwe muziek uit! <strong>'LEVEN'</strong> is de
          alomvattende titel van de vijf liedjes op haar gloednieuwe{" "}
          <strong>EP</strong>. Van een pril leven dat niet heeft mogen zijn, de
          intense liefde voor je kind, tot de uitdagingen die het leven met zich
          meebrengt: "Het draait en het duizelt, en je zit er middenin." Met
          haar openhartige teksten en rijke gevoelswereld weet Emma Lou recht je
          hart te raken. Een nieuwe fase in haar songwriting; volwassener en
          indringender. Verwacht melancholische melodieën, akoestische
          gitaarpartijen, stevige synths en dromerige vocalen, aangevuld met
          virtuoze strijkers.
        </p>

        <p>
          Dat Emma Lou eerder indruk maakte, blijkt uit de uitverkochte release
          van haar debuutalbum <strong>'Nooit Alleen'</strong> in 2016 in
          Theater De Lieve Vrouw. Op dit conceptalbum geeft ze zich bloot, met
          een flinke dosis humor en een scherpe, cynische ondertoon waar nodig.
          Dit succes leidde tot de muziektheatervoorstelling{" "}
          <strong>'Emma WHO?!'</strong>, waarmee ze in 2017-2018 door Nederland
          toerde, met optredens in onder andere een volgeboekt Herman van Veen
          Arts Center. Haar talent werd bevestigd met de publieksprijs van het
          Utrechts Kleinkunst Festival.
        </p>

        <p>
          <img alt="live" src={band} width="100%" style={{ marginTop: 12 }} />
        </p>

        <p>
          Emma Lou bleef daarna niet stilzitten. Twee losse singles,{" "}
          <strong>'Hier Is Het Nu'</strong> en <strong>'Zing Nog Eens'</strong>,
          markeren haar overgang naar een nieuwe sound. Met 'Zing Nog Eens' laat
          ze zien dat ze niet bang is voor zware thema's, integendeel, haar
          muziek werkt helend.
        </p>

        <div className="feature-image">
          <img alt="Emma Lou" src={paars} width="100%" />
        </div>

        <p>
          Dat Emma Lou zelf artiest zou worden zat er al jong in. Ze groeide op
          tussen de gitaren en violen. Dat laatste instrument speelt nog steeds
          een belangrijke rol in haar repertoire. Na jaren klassiek les te
          hebben gehad, rondde de muzikante zowel het conservatorium van Zwolle
          als Arnhem af. Daarmee is ze volleerd docent én jazz- en popzangeres.
          Tijdens deze studies richtte ze zich verder volledig op songwriting.
          De combinatie van talenten resulteerde in 2014 in de
          afstudeervoorstelling ‘Alleen Gelukkig’, waar ze de ArtEZ
          Vrienden-Publieksprijs mee in de wacht sleepte.
        </p>

        <QuoteBox
          inline
          padding={"16px 32px"}
          quote={`“Emma Lou schreed het podium op als een ware diva, begeleid door een zeer muzikaal combo en zelfs een strijkkwartet”`}
        />

        <p>
          schreef een overdonderde jury destijds. Sindsdien is Emma Lou een
          publiekslieveling bij jong en oud.
        </p>

        <p>
          Sindsdien is Emma Lou een geliefd artiest voor jong en oud, klaar om
          opnieuw harten te veroveren met haar unieke stem en diepgaande
          teksten.
        </p>
      </Bio>

      <MediaBottom />
    </Main>
  );
};
